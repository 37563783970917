import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Background } from "react-imgix";

import VideoBlock from "../../blocks/VideoBlock";

const ImageHeaderWrapper = styled.div`
  ${tw`flex flex-wrap`}
`

const ImageHeaderLeftSide = styled.div`
  width: 35%;
  height: calc(100vh - 120px);

  @media screen and (max-width: 64em) {
    width: 100%;
    height: auto;
  }
`

const ImageHeaderRightSide = styled.div`
  ${tw`flex-no-grow flex-shrink relative`}
  width: 65%;
  height: calc(100vh - 120px);

  & > div {
    height: 100%;
  }

  @media screen and (max-width: 64em) {
    width: 100%;
    height: ${props => (props.video) ? 'auto' : '250px'};
  }
`

const ImageHeaderLeftWrapper = styled.div`
  ${tw`flex flex-col justify-center pl-12 pr-3`}
  max-width: 550px;
  height: calc(100vh - 120px);
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 64em) {
    ${tw`max-w-full justify-start m-0 mt-5 mb-10 ml-5 mr-5 p-0 min-h-full h-auto`}
  }
`

const H1 = styled.h1`
  ${tw`font-montserrat text-black text-4xl font-light m-0 mb-1`}
  line-height: 46px;

  p {
    margin: 0px;
  }
`

const H2 = styled.h2`
  ${tw`font-montserrat text-grey text-4xl font-light m-0`}
  line-height: 46px;

  p {
    margin: 0px;
  }
`

const Description = styled.div`
  ${tw`font-raleway text-grey text-blog-text text-lg leading-normal font-medium m-0 mt-6`}

  p {
    margin: 0px;
  }

  a {
    color: #4D8DCD;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`

const SmallImageHeader = ({ title, subtitle, description, image, video, autoplay }) => (
  <ImageHeaderWrapper>
    <ImageHeaderRightSide video={video}>
      {image ? <Background src={image} /> : null }
      {video ? <VideoBlock url={video} autoplay={autoplay} /> : null }
    </ImageHeaderRightSide>

    <ImageHeaderLeftSide>
      <ImageHeaderLeftWrapper>
        {(subtitle) ? <H2 dangerouslySetInnerHTML={{ __html: subtitle }} /> : null}
        <H1 dangerouslySetInnerHTML={{ __html: title }} />
        {(description) ? <Description dangerouslySetInnerHTML={{ __html: description }} /> : null}
      </ImageHeaderLeftWrapper>
    </ImageHeaderLeftSide>
  </ImageHeaderWrapper>
)

SmallImageHeader.propTypes = {
  // Page title
  title: PropTypes.string.isRequired,
  // Page subtitle
  subtitle: PropTypes.string,
  // Image details
  image: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.string,
  autoplay: PropTypes.bool
}

SmallImageHeader.defaultProps = {
  subtitle: '',
  description: '',
  image: '',
  video: '',
  autoplay: false
}

export default SmallImageHeader
